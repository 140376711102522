import React from 'react';

import Layout from '~/components/Layout';
import { Break } from '~/components/Section';
import { IMAGE_BANNER_CONTACT } from '~/constants/images';
import { Container, Map, Card, Image } from './style';

export default () => {
  return (
    <Layout
      label="Kontakt"
      bannerImg={IMAGE_BANNER_CONTACT}
      bannerPortrait
      bannerDisabled
    >
      <Container>
        <Card>
          Zapraszam na pierwsze spotkanie w moim gabinecie, które ma charakter{' '}
          <b>konsultacyjny</b> i służy wstępnemu omówieniu problemu z jakim
          przychodzi pacjent. W celu umówienia pierwszego spotkania w moim
          gabinecie zachęcam do kontaktu telefonicznego.
          <Break />
          Anna Gębczak Psycholog
          <br />
          ul. Wrocławska 14/5 Opole
          <br />
          Telefon <b>+48 510 710 752</b>
          <br />
          <br />
          <Map src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d920.2031608158798!2d17.912522489394277!3d50.671074349045114!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8ad025066fa9ba54!2sAnna%20G%C4%99bczak%20Psycholog!5e0!3m2!1spl!2spl!4v1589897301791!5m2!1spl!2spl" />
        </Card>
        <Image src={IMAGE_BANNER_CONTACT} />
      </Container>
    </Layout>
  );
};
