import styled from 'styled-components';

import { CardCss, Content } from '~/components/Section';
import { MOBILE_VIEW } from '~/constants/design';

export const Map = styled.iframe`
  width: 100%;
  height: 384px;
  border: none;
`;

export const Container = styled(Content)`
  display: flex;

  @media (max-width: ${MOBILE_VIEW}px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  ${CardCss}
  flex: 1;
`;

export const Image = styled.img`
  max-height: 384px;
  margin-left: 48px;
  border-radius: 12px;
  overflow: hidden;
  object-fit: cover;
  object-position: center;

  @media (max-width: ${MOBILE_VIEW}px) {
    width: 100%;
    max-width: 384px;
    max-height: unset;
    margin: 48px auto 0px;
  }
`;
